import React from "react";
import HeroSection from "./../components/HeroSection";
import ContactSection from "./../components/ContactSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  return (
      <>
        <HeroSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="DESIGN. INNOVATION. CREATIVITY. TECHNOLOGY."
            subtitle="We are an innovation studio with a focus on cutting edge technology."
            buttonText="Get Started"
            buttonColor="primary"
            image="/assets/07.png"
            buttonOnClick={() => {
              // Navigate to pricing page
              router.navigate("/#contactus");
            }}
            linkText = "Get Started"
            linkHref = "/#contactus"
        ></HeroSection>
        <div id="contactus"></div>
        <ContactSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Grow faster, Transform quickly"
            subtitle="Our elite team of executives partner with you to give you a fresh, new perspective on how you're doing, how to do it better, and then take action to get you there."
            buttonText="Send message"
            buttonColor="primary"
            showNameField={true}
            inputSize="md"
        ></ContactSection>
      </>
  );
}

export default IndexPage;
